import { Practitioners } from '@booking/api';
import {
  formatPrice,
  getDayTypeAndTimeOfDayFromSelectedTime,
  getFormattedAppointmentDateTime,
  type SurchargeType,
  useClinicStore,
  useNavigationStore,
  usePersonalDataStore,
  useServiceTreeStore,
} from '@booking/shared';
import {
  Actions,
  BookingInfo,
  Clinic,
  Locales,
  Practitioner,
} from '@booking/types';
import PractitionerInfo from '@components/PractitionerInfo';
import useAnalytics from '@hooks/useAnalytics';
import { useQuery } from '@tanstack/react-query';
import { clinicToGoogleMapsLink } from '@utils/mapQuery';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

type BookingSummaryProps = {
  hasClinic: boolean;
  hasTimeStamp: boolean;
  practitionerId?: string;
  selectedDate?: string;
};

export default function BookingSummary({
  hasClinic,
  hasTimeStamp,
  practitionerId,
}: BookingSummaryProps) {
  const { personalDetails } = usePersonalDataStore()((state) => ({
    personalDetails: state.personalDetails,
  }));

  const queries = useNavigationStore((state) => state.queries);
  const { getServiceName, getSpecialityName, getServicePrice, getSurcharge } =
    useServiceTreeStore(
      ({
        getServiceName,
        getSpecialityName,
        getServicePrice,
        getSurcharge,
      }) => ({
        getServiceName,
        getSpecialityName,
        getServicePrice,
        getSurcharge,
      }),
    );

  const locale = useRouter().locale as Locales;

  const specialityName = getSpecialityName(
    queries.vertical,
    queries.speciality,
    locale,
  );

  const serviceName =
    queries.service && getServiceName(queries.service, locale);

  const { clinic } = useClinicStore()((state) => ({ clinic: state.clinic }));

  const servicePrice = getServicePrice(queries.service) ?? 0;

  const hideSurchargeInfo = hasTimeStamp === false || servicePrice === 0;

  const { typeOfDay, isEvening } = getDayTypeAndTimeOfDayFromSelectedTime(
    personalDetails.date,
    personalDetails.time,
  );
  const { surchargePrice, surchargeType } = getSurcharge(
    queries.service,
    typeOfDay,
    isEvening,
    hideSurchargeInfo,
  );

  const { data: practitioner } = useQuery(
    ['practitioner', practitionerId],
    ({ signal }) =>
      Practitioners.getPractitioner({
        practitionerId,
        signal,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!practitionerId,
    },
  );

  return (
    <div className="m-auto mb-10 flex w-full rounded-lg bg-black/20 p-6">
      <SummaryInfo
        hasClinic={hasClinic}
        hasTimeStamp={hasTimeStamp}
        serviceName={serviceName}
        locale={locale}
        personalDetails={personalDetails}
        specialityName={specialityName}
        clinic={clinic}
        practitioner={practitioner}
        price={servicePrice}
        surchargePrice={surchargePrice}
        surchargeType={surchargeType}
      />
    </div>
  );
}

const SummaryInfoSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div className="flex flex-col gap-4">
          <div className="skeleton h-4 w-20 rounded-md"></div>
          <div className="skeleton h-4 w-28 rounded-md"></div>
        </div>
      </div>
      <div className="skeleton h-20 w-40 rounded-md"></div>
    </div>
  );
};

type SummaryInfoProps = {
  hasTimeStamp: boolean;
  hasClinic: boolean;
  clinic?: Clinic;
  specialityName?: string;
  serviceName?: string;
  personalDetails: BookingInfo;
  locale: Locales;
  practitioner?: Practitioner;
  price?: number;
  surchargeType?: SurchargeType;
  surchargePrice: number;
};

const SummaryInfo = ({
  hasTimeStamp,
  hasClinic,
  clinic,
  specialityName,
  serviceName,
  personalDetails,
  locale,
  practitioner,
  price,
  surchargeType,
  surchargePrice,
}: SummaryInfoProps) => {
  const { t } = useTranslation(['booking', 'common']);
  const setEventProps = useAnalytics();
  const hasSurcharge = hasTimeStamp && surchargeType;
  const isLoading =
    !clinic?.name &&
    locale &&
    !serviceName &&
    !specialityName &&
    !personalDetails.date &&
    !price;

  return isLoading ? (
    <SummaryInfoSkeleton />
  ) : (
    <div className="flex w-full flex-col justify-start">
      {practitioner ? (
        <PractitionerInfo practitioner={practitioner} locale={locale} />
      ) : null}

      {hasTimeStamp && (
        <p className="pb-2">
          {getFormattedAppointmentDateTime(
            personalDetails.date!,
            personalDetails.time!,
            locale,
          )}
        </p>
      )}

      <p className="text-[17px] font-medium leading-7">
        {specialityName}, {serviceName}
      </p>

      {hasClinic && (
        <p className="text-[17px] font-normal">
          {clinic?.name}
          <br />
          {clinic?.address.street}, {clinic?.address.zip} {clinic?.address.city}
        </p>
      )}
      {price ? (
        <p id="price" className="pt-2 text-[17px]">
          {hasSurcharge ? (
            <>
              <SummaryRow
                label={t(`steps.confirm.price`)}
                value={`${formatPrice(price + surchargePrice)},-`}
              />
              <div>
                {t('steps.select_datetime.surcharge.includes', {
                  surchargeType: t(
                    `steps.select_datetime.surcharge.${surchargeType}`,
                  ).toLowerCase(),
                })}
              </div>
            </>
          ) : (
            <SummaryRow
              label={t('steps.confirm.price')}
              value={`${formatPrice(price)},-`}
            />
          )}
          {price > 0 ? <span>{t('steps.confirm.price_info')}</span> : null}
        </p>
      ) : null}
      <div className="mt-6 w-1/2 rounded-full border border-white px-2 py-1 text-center no-underline sm:w-1/3">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={clinicToGoogleMapsLink(clinic)}
          onClick={() => {
            setEventProps({
              event: {
                action: Actions.OpenMap,
              },
            });
          }}
        >
          <span className="text-lg text-white">
            {t('map.button_text', { ns: 'common' })}
          </span>
        </a>
      </div>
    </div>
  );
};

const SummaryRow = ({ label, value }: { label: string; value: string }) => (
  <div>
    <span className="font-medium">{`${label}: `}</span>
    <span>{value}</span>
  </div>
);
